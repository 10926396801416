import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Target from '../assets/svg/target.svg'

const Purpose = ({ displayTitle }) => {
    const { t } = useTranslation('purpose')

    return (
        <div className="purposePage" id="purpose">
            {displayTitle && (
                <>
                    <h2 className="pageTitle">{t('header')}</h2>
                    <hr />
                </>
            )}
            <div className="purposeContents">
                <Target />
                <div>
                    <h3>{t('description')}</h3>
                    <p>{t('paragraph')}</p>
                </div>
            </div>
        </div>
    )
}

export default Purpose

Purpose.defaultProps = {
    displayTitle: false,
}

Purpose.propTypes = {
    displayTitle: PropTypes.bool,
}
