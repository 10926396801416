import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Purpose from '../components/Purpose'

const PurposePage = () => (
    <Layout>
        <SEO title="purpose:title" />
        <Purpose displayTitle />
    </Layout>
)

export default PurposePage
